.calc-wrp__container {
  width: 90vw;
  max-width: 62.25rem;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 64rem) {
  .calc-wrp__container {
    width: auto;
    max-width: 62.25rem;
  }
}

.calc-wrp__form-block-wrapper {
  border-radius: .3125rem;
  margin-bottom: 0;
  box-shadow: 0 4px 80px rgba(0, 0, 0, .1);
}

.calc-wrp__form {
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 3.75rem;
  display: flex;
}

@media (max-width: 48rem) {
  .calc-wrp__form {
    padding: 2.5rem 0 0;
  }
}

.calc-wrp__line {
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 2.5rem;
  display: flex;
}

@media (max-width: 48rem) {
  .calc-wrp__line {
    padding: 0 1.25rem;
  }
}

.calc-wrp__item {
  width: 22.8%;
  position: relative;
}

@media (max-width: 62rem) {
  .calc-wrp__item {
    width: 100%;
    position: relative;
  }

  .calc-wrp__item.full-item {
    width: 100%;
  }
}

@media (max-width: 36rem) {
  .calc-wrp__item.full-item {
    margin-top: 1.25rem;
  }
}

.calc-wrp__item .switcher-parent {
  background: #eef2f4;
  border-radius: 2px;
  align-items: center;
  padding: .125rem;
  display: flex;
  position: absolute;
  top: -.3125rem;
  right: 0;
}

.calc-wrp__item .switcher-parent .switcher-label {
  margin-bottom: 0;
  display: block;
  position: relative;
}

.calc-wrp__item .switcher-parent .radio-icon {
  color: #232323;
  -o-transition: all .3s;
  width: 1.5rem;
  height: 1.25rem;
  background: none;
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  font-size: .75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  transition: all .3s;
  display: flex;
}

.calc-wrp__item .switcher-parent input[type="radio"] {
  opacity: 0;
  z-index: -1;
  position: absolute;
  left: 0;
}

.calc-wrp__item .switcher-parent input[type="radio"]:checked ~ .radio-icon {
  color: #fff;
  -o-transition: all .3s;
  background-color: #ffc842;
  font-size: .75rem;
  line-height: 100%;
  transition: all .3s;
}

.calc-wrp__item .switcher-parent .radio-label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.calc-wrp__item .calculator-from__header {
  margin-bottom: 1.875rem;
}

.calc-wrp__item .calculator-from__input {
  color: #333;
}

.calc-wrp__inner-line {
  -webkit-box-align: self-end;
  -ms-flex-align: self-end;
  width: 100%;
  flex-wrap: wrap;
  align-items: self-end;
  gap: 1.25rem;
  display: flex;
}

.calc-wrp__btn-calc {
  cursor: pointer;
  height: 3.5rem;
  -o-transition: all 300ms ease;
  background-color: #ffc842;
  outline: none;
  margin-top: 1.875rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  transition: all .3s;
  position: relative;
  color: #fff !important;
}

.calc-wrp__btn-calc:hover {
  background-color: #000;
}

.calc-wrp__header {
  color: #ffc842;
  margin-bottom: 1.5rem;
  font-size: 1.125rem;
  line-height: 140%;
}

.calc-wrp input {
  color: #000;
  outline: none;
}

.calc-wrp input[type="submit"] {
  cursor: pointer;
}

@media (max-width: 62rem) {
  .calc-wrp__inner-line {
    flex-wrap: wrap;
  }

  .calc-wrp input {
    width: 100%;
  }
}

@media (max-width: 48rem) {
  .calc-wrp {
    margin: .5rem;
  }
}

.calc-wrp .calculator-form__inputs-wrapper {
  width: 100%;
  align-items: flex-start;
}

.calc-wrp .calculator-form__content-wrapper {
  padding: 1.25rem;
}

.calc-wrp .calculator-form__header {
  margin-bottom: 1.875rem;
}

.calc-wrp .calculator-form__tab-link:hover {
  color: #fff;
  background-color: #ffc842;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.calc-wrp .calculator-form__inputs-line {
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

@media (max-width: 48rem) {
  .calc-wrp .calculator-form__inputs-line {
    flex-flow: wrap;
  }

  .calc-wrp .calculator-form__inputs-line ul {
    width: 100%;
  }
}

.calc-wrp .calculator-form__result-title {
  width: 35.4%;
  margin-right: 1rem;
}

@media (max-width: 48rem) {
  .calc-wrp .calculator-form__result-title {
    width: auto;
    margin-bottom: 1rem;
  }
}

.calc-wrp .calculator-form__result-wrapper {
  width: 50%;
  min-width: 8.125rem;
  text-align: center;
  margin-right: .313em;
}

.calc-wrp .calculator-form__result-wrapper.full-width {
  width: 50%;
  min-width: 8.125rem;
  text-align: center;
}

.calc-wrp .calculator-form__result-wrapper.full-width .total {
  width: 100%;
}

@media (max-width: 62rem) {
  .calc-wrp .calculator-form__result-wrapper.full-width {
    width: 65%;
  }
}

@media (max-width: 48rem) {
  .calc-wrp .calculator-form__result-wrapper.full-width {
    width: auto;
  }
}

@media (max-width: 62rem) {
  .calc-wrp .calculator-form__result-wrapper {
    width: 65%;
  }
}

@media (max-width: 48rem) {
  .calc-wrp .calculator-form__result-wrapper {
    width: 100%;
  }
}

@media (max-width: 36rem) {
  .calc-wrp .calculator-form__result-wrapper {
    flex-direction: column;
  }
}

.calc-wrp .calculator-form__result-input {
  height: auto;
  width: 25%;
  min-width: 8.125rem;
  background: #eef2f4;
  padding: .75rem 1.625rem;
  position: relative;
}

.calc-wrp .calculator-form__result-input.full-width {
  width: calc(50% + .313em);
  min-width: 16.6rem;
}

@media (max-width: 62rem) {
  .calc-wrp .calculator-form__result-input.full-width {
    width: 100%;
  }
}

@media (max-width: 48rem) {
  .calc-wrp .calculator-form__result-input.full-width {
    width: 100%;
  }
}

@media (max-width: 36rem) {
  .calc-wrp .calculator-form__result-input.full-width {
    width: 100%;
    min-width: 100%;
  }
}

@media (max-width: 62rem) {
  .calc-wrp .calculator-form__result-input {
    width: 50%;
    margin-top: 1.2rem;
  }
}

@media (max-width: 48rem) {
  .calc-wrp .calculator-form__result-input {
    width: 48%;
  }
}

@media (max-width: 36rem) {
  .calc-wrp .calculator-form__result-input {
    width: 47%;
    min-width: 47%;
    padding: .75rem;
  }
}

.calc-wrp .calculator-form__result-input .mobile-comment {
  display: none;
  position: absolute;
  top: -1.75rem;
  left: 0;
}

@media (max-width: 48rem) {
  .calc-wrp .calculator-form__result-input .mobile-comment {
    color: #828282;
    margin: .625rem 0;
    font-size: .75rem;
    font-weight: 400;
    line-height: 100%;
    display: block;
  }
}

.calc-wrp .calculator-form__result {
  min-width: 8.125rem;
  text-align: center;
  margin-right: .313em;
}

.calc-wrp .calculator-form__result.total {
  width: 50%;
  min-width: 8.125rem;
  letter-spacing: -.03125em;
  color: #ffc842;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 100%;
  position: relative;
}

.calc-wrp .calculator-form__result.total:before {
  content: "$";
  margin-right: .3125em;
  left: 0;
}

@media (max-width: 62rem) {
  .calc-wrp .calculator-form__result.total {
    width: 100%;
  }
}

@media (max-width: 36rem) {
  .calc-wrp .calculator-form__result.total {
    text-align: left;
    margin-bottom: .3rem;
  }
}

.calc-wrp .calculator-form__content-top {
  align-items: center;
  padding: 1.25rem 2.5rem;
}

.calc-wrp .calculator-form__content-top.result-block {
  width: 100%;
  margin-bottom: 1.25rem;
  padding-bottom: 1.25rem;
}

.calc-wrp .calculator-form__content-top.result-block:last-child {
  margin-bottom: 0;
}

@media (max-width: 48rem) {
  .calc-wrp .calculator-form__content-top.result-block {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
}

@media (max-width: 36rem) {
  .calc-wrp .calculator-form__content-top {
    padding: 1.25rem .9375rem;
  }
}

.calc-wrp .calculator-form__tabs {
  background-color: #eef2f4;
  margin-top: 0;
}

@media (max-width: 48rem) {
  .calc-wrp .calculator-form__tabs {
    width: 100%;
    border-radius: 0;
    margin-top: 0;
    overflow: hidden;
  }
}

@media (max-width: 48rem) {
  .calc-wrp .calculator-form__tab-menu {
    border-bottom: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: .625rem;
    padding: 1.25rem 1.25rem 0;
  }
}

.calc-wrp .calculator-form__tab-link.w--current {
  background-color: #ffc842;
}

@media (max-width: 48rem) {
  .calc-wrp .calculator-form__tab-link {
    width: 100%;
    height: auto;
    background-color: #fff;
    border-radius: 5px !important;
    padding: 1.125rem !important;
  }
}

.calc-wrp .calculator-form__list {
  width: 35.4%;
  margin-right: 1rem;
}

.calc-wrp .calculator-form__title {
  width: 25%;
  min-width: 9.5rem;
  text-align: center;
}

.calc-wrp .calculator-form__title.name {
  text-align: left;
  width: 35.4%;
  margin-right: 1rem;
}

@media (max-width: 62rem) {
  .calc-wrp .calculator-form__title {
    width: 33%;
  }
}

.calc-wrp .calculator-form__titles-wrapper {
  padding: .5rem 2.5rem;
}

@media (max-width: 48rem) {
  .calc-wrp .calculator-form__titles-wrapper {
    display: none;
  }
}

.calc-wrp .calculator-form__list-item {
  width: 100%;
  height: auto;
  border-bottom: none;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: .75rem 0;
  display: flex;
}

@media (max-width: 36rem) {
  .calc-wrp .calculator-form__list-item {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    display: flex;
  }

  .calc-wrp .calculator-form__list-item.result-item {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .calc-wrp .calculator-form__list-item.result-item .name-result {
    margin-bottom: 0;
    margin-right: .9375rem;
  }

  .calc-wrp .calculator-form__list-item .name-result {
    margin-bottom: .9375rem;
  }
}

.calc-wrp .calculator-form__list-item:last-child {
  border: none;
}

.calc-wrp .calculator-form__list-item .name-result {
  letter-spacing: -.03125em;
  color: rgba(92, 92, 92, .6);
  font-size: .875rem;
  font-weight: 400;
  line-height: 100%;
}

.calc-wrp .calculator-form__list-item .name-result.total {
  color: #232323;
  font-weight: 600;
}

.calc-wrp .calculator-form__list-item .result {
  letter-spacing: -.03125em;
  color: #232323;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
}

.calc-wrp .calculator-form__text-result-wrapper {
  background: #fff;
  border-radius: 5px;
  padding: 2.5rem;
}

.calc-wrp .calculator-form__text-result-wrapper .text {
  letter-spacing: -.03125em;
  color: #232323;
  font-size: .875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 200%;
}

.calc-wrp .calculator-form__text-result-wrapper .text p {
  margin-bottom: 1.25rem;
}

.calc-wrp .calculator-form__text-result-wrapper .text p:last-child {
  margin-bottom: 0;
}

.calc-wrp .calculator-form__text-result-wrapper .text p .marker {
  font-weight: 600;
  position: relative;
}

.calc-wrp .calculator-form__text-result-wrapper .text p .marker.percent:after {
  content: "%";
  margin-left: .125rem;
  right: 0;
}

.calc-wrp .calculator-form__text-result-wrapper .text p .marker.currency:before {
  content: "$";
  margin-right: .125rem;
  left: 0;
}

@media (max-width: 48rem) {
  .calc-wrp .calculator-form__text-result-wrapper {
    padding: 1.5rem;
  }
}

@media (max-width: 36rem) {
  .calc-wrp .calculator-form .w-tab-link {
    padding: 1.125rem 1.875rem;
  }
}

/*# sourceMappingURL=index.css.map */
